// ** Icons Import
import { AlertTriangle, Key } from 'react-feather'

export default [
	{
		header: 'LOGS',
		action: 'manage',
		resource: 'logs'
	},
	{
		id: 'authorization-log',
		title: 'Authorization',
		icon: <Key size={20} />,
		action: 'manage',
		resource: 'logs',
		navLink: '/logs/authorization'
	},
	{
		id: 'suspicios-activity-log',
		title: 'Suspicious Activity',
		icon: <AlertTriangle size={20} />,
		action: 'manage',
		resource: 'logs',
		navLink: '/logs/suspicious-activity'
	}
]
