// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
import { encryptData, decryptData } from '@utils'

// ** UseJWT import to get config
// import useJwt from '@src/auth/jwt/useJwt'

// const config = useJwt.jwtConfig

/*const initialUser = () => {
	const item = window.localStorage.getItem('userData')
	//** Parse stored json or if none return initialValue
	return item ? JSON.parse(item) : {}
}*/

const isDevMode = process.env.REACT_APP_NODE_ENV === 'development'

export const authSlice = createSlice({
	name: 'authentication',
	initialState: {
		userData: decryptData(localStorage.getItem('userData')) ?? null,
		account: localStorage.getItem('account') ?? null
	},
	reducers: {
		handleLogin: (state, action) => {
			const { userData, accessToken, refreshToken } = action.payload
			state.userData = userData
			localStorage.setItem('userData', encryptData(userData))
			userData.accounts.map((account) => {
				if (account.status === 'active') {
					state.account = account
					localStorage.setItem('account', JSON.stringify(account))
					return true
				}
			})
			if (isDevMode) {
				// Store tokens in localStorage only in development
				localStorage.setItem('accessToken', accessToken)
				localStorage.setItem('refreshToken', refreshToken)
			}
		},
		handleLogout: () => {
			//state.userData = null
			localStorage.removeItem('userData')
			localStorage.removeItem('account')
			if (isDevMode) {
				// Remove tokens from localStorage only in development
				localStorage.removeItem('accessToken')
				localStorage.removeItem('refreshToken')
			}
		}
	}
})

export const { handleLogin, handleLogout } = authSlice.actions

export default authSlice.reducer
