import { createSlice } from '@reduxjs/toolkit'

const notificationsSlice = createSlice({
	name: 'notifications',
	initialState: [],
	reducers: {
		addNotification: (state, action) => {
			state.push(action.payload)
		},
		resetNotifications: () => [] // Action to reset the state
	}
})

export const { addNotification, resetNotifications } = notificationsSlice.actions
export default notificationsSlice.reducer
