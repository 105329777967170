// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAddresBook = createAsyncThunk('appEmail/getAddressBook', async () => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/address-book/get-contacts`)
	return {
		data: response.data
	}
})

export const getFolders = createAsyncThunk('appEmail/getFolders', async ({ account }) => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/mailbox/folders`, {
		params: { account: account.username }
	})
	// console.log('Dispatched folders: ', response.data)
	return {
		data: response.data
	}
})

/**
 * Check if there are some shared folders for the account
 */
export const getSharedFoldersList = createAsyncThunk('appEmail/getSharedFoldersList', async ({ account }) => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/email-accounts/shared-folders`, {
		params: { account_id: account.id }
	})
	// console.log('Dispatched shared folders list: ', response.data)
	return {
		data: response.data
	}
})

export const getSharedFolders = createAsyncThunk('appEmail/getSharedFolders', async ({ account }) => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/mailbox/shared-folders`, {
		params: { account: account.username }
	})
	// console.log('Dispatched shared folders: ', response.data)
	return {
		data: response.data
	}
})

export const createFolder = createAsyncThunk('appEmail/createFolder', async ({ account, parentFolder, folderName }) => {
	const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/mailbox/folders/add-new`, {
		account: account.username,
		parentFolder,
		folderName
	})
	return response.data
})

export const deleteFolder = createAsyncThunk('appEmail/deleteFolder', async ({ account, folder }) => {
	// console.log('Folder to delete: ', folder)
	const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/mailbox/folders/delete`, {
		account: account.username,
		folder
	})
	return response.data
})

export const getMails = createAsyncThunk(
	'appEmail/getMails',
	async ({ account, folder, isShared = false, sharedBy = null, page = 1, perPage = 20 }) => {
		const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/mailbox/folders/${folder}`, {
			params: { account: account.username, isShared, sharedBy, page, perPage }
		})
		// console.log('Dispatched mails: ', response.data)
		return {
			params: { account, folder },
			data: response.data
		}
	}
)

export const searchMails = createAsyncThunk('appEmail/searchMails', async ({ account, searchIn, sq, page = 1, perPage = 10 }) => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/mailbox/search`, {
		params: { account: account.username, searchIn, sq, page, perPage }
	})
	// console.log('Search result: ', response.data)
	return {
		params: { account, sq },
		data: response.data
	}
})

// TODO: delete
export const updateMails = createAsyncThunk('appEmail/updateMails', async ({ emailIds, dataToUpdate }, { dispatch, getState }) => {
	const response = await axios.post('/apps/email/update-emails', { emailIds, dataToUpdate })
	await dispatch(getMails(getState().email.params))
	return {
		emailIds,
		dataToUpdate,
		data: response.data
	}
})

export const selectCurrentMail = createAsyncThunk('appEmail/selectCurrentMail', async ({ account, activeFolder, folder, uid }) => {
	// console.log(`Dispatching email for ${account.username} from ${folder}`)
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/mailbox/email/read/${folder}/${uid}`, {
		params: { account: account.username, isShared: activeFolder.isShared ?? false, sharedBy: activeFolder.sharedBy ?? null }
	})
	// console.log('Dispatched email: ', response.data)
	return {
		activeFolder, // Active folder ( mail.folder can be defferent if mail is in Unread or Marked folder )
		data: response.data
	}
})

export const moveMails = createAsyncThunk('appEmail/moveMails', async ({ account, mails, to }) => {
	// console.log('Mails to move: ', mails)
	// console.log('Destination folder: ', to)
	const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/mailbox/emails/move`, {
		account: account.username,
		mails,
		to
	})
	return response.data
})

export const sendMail = createAsyncThunk('appEmail/sendMail', async ({ account, email }) => {
	// console.log('Mail to send: ', email)
	const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/mailbox/email/send`, {
		account: account.username,
		email
	})
	return response.data
})

export const saveMail = createAsyncThunk('appEmail/saveMail', async ({ account, email }) => {
	// console.log('Mail to save: ', email)
	const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/mailbox/email/save`, {
		account: account.username,
		email
	})
	return response.data
})

export const flagMails = createAsyncThunk('appEmail/flagMails', async ({ account, uids, folder }) => {
	const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/mailbox/emails/flag`, {
		account: account.username,
		uids,
		folder
	})
	return response.data
})

export const markUnread = createAsyncThunk('appEmail/markUnread', async ({ account, uids, folder }) => {
	const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/mailbox/emails/mark-unread`, {
		account: account.username,
		uids,
		folder
	})
	return response.data
})

export const appEmailSlice = createSlice({
	name: 'appEmail',
	initialState: {
		folders: [],
		folder: null,
		sharedFoldersList: [],
		sharedFolders: [],
		mails: {},
		params: {},
		emailsMeta: {},
		selectedMails: {},
		currentMail: null,
		addressBook: [],
		searchResults: {}
	},
	reducers: {
		selectMail: (state, action) => {
			const { messageId, folder, uid } = action.payload
			const selected = { ...state.selectedMails }
			if (!selected[messageId]) {
				selected[messageId] = { folder, uid }
			} else {
				delete selected[messageId]
			}
			state.selectedMails = selected
		},
		selectAllMail: (state, action) => {
			const { checked, folder } = action.payload
			const selected = {}
			if (checked) {
				// Ensure the folder exists and has pages
				if (state.mails[folder]?.pages) {
					// Iterate through each page in the folder
					Object.keys(state.mails[folder].pages).forEach((pageKey) => {
						state.mails[folder].pages[pageKey].forEach((mail) => {
							selected[mail.messageId] = {
								folder: mail.folder,
								uid: mail.uid
							}
						})
					})
				}

				state.selectedMails = selected
			} else {
				state.selectedMails = {}
			}
		},
		resetSelectedMail: (state) => {
			state.selectedMails = {}
		},
		resetSearchResults: (state) => {
			state.mails = {
				...state.mails,
				['Search']: {
					...state.mails['Search'], // Preserve existing folder data
					pages: {}
				}
			}
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAddresBook.fulfilled, (state, action) => {
				// console.log('Addresses dispatched...')
				state.addressBook = action.payload.data
			})
			.addCase(getFolders.fulfilled, (state, action) => {
				// console.log('Folders dispatched...')
				state.folders = action.payload.data
			})
			.addCase(getSharedFolders.fulfilled, (state, action) => {
				// console.log('Shared folders dispatched...')
				state.sharedFolders = action.payload.data
			})
			.addCase(getSharedFoldersList.fulfilled, (state, action) => {
				// console.log('Shared folders list obtained...')
				state.sharedFoldersList = action.payload.data
			})
			.addCase(createFolder.fulfilled, (state, action) => {
				if (action.payload.created) {
					const newFolder = action.payload.folder
					if (newFolder.parent) {
						// Find the parent folder and add the new folder as a child
						const addSubFolder = (folders) => {
							return folders.map((folder) => {
								if (folder.path === newFolder.parent.path) {
									return {
										...folder,
										children: [newFolder, ...folder.children]
									}
								}
								return {
									...folder,
									children: addSubFolder(folder.children || [])
								}
							})
						}
						state.folders = addSubFolder(state.folders)
					} else {
						// Add as a top-level folder if no parent
						// state.folders = [...state.folders, { ...newFolder, children: [] }]
						state.folders = [...state.folders, newFolder]
					}
				}
			})
			.addCase(deleteFolder.fulfilled, (state, action) => {
				if (action.payload.deleted) {
					const folderToDelete = action.payload.folder

					// Recursive function to remove the folder from the hierarchy
					const deleteFolderRecursively = (folders) => {
						return folders
							.filter((folder) => folder.path !== folderToDelete) // Remove the folder if it matches
							.map((folder) => ({
								...folder,
								children: deleteFolderRecursively(folder.children || []) // Recurse into children
							}))
					}

					state.folders = deleteFolderRecursively(state.folders)
					// state.folders = state.folders.filter((folder) => folder.name !== action.payload.folder)
				}
			})
			.addCase(getMails.fulfilled, (state, action) => {
				let currMail = null

				// Check if the current mail exists and update it if needed
				if (state.currentMail !== null && state.currentMail !== undefined) {
					currMail = action.payload.data.emails.find((i) => i.uid === state.currentMail.uid)
				}

				state.currentMail = currMail
				state.params = action.payload.params
				state.folder = action.payload.data.folder

				// Ensure the emails are stored in the right folder
				const folderPath = action.payload.data.folder.path
				const emails = action.payload.data.emails
				const emailsMeta = action.payload.data.emailsMeta
				const currentPage = action.payload.data.pagination?.page || 1 // Default to page 1 if not provided

				// Store the emails for the current folder separately
				if (!state.mails) {
					state.mails = {}
				}

				state.mails = {
					...state.mails,
					[folderPath]: {
						...state.mails[folderPath], // Preserve existing folder data
						pages: {
							...(state.mails[folderPath]?.pages || {}), // Preserve existing pages
							[currentPage]: emails // Add/Update current page with new emails
						}
					}
				}

				// Similarly, store emails metadata separately for each folder
				if (!state.emailsMeta) {
					state.emailsMeta = {}
				}

				state.emailsMeta = {
					...state.emailsMeta,
					[folderPath]: emailsMeta
				}
			})
			.addCase(searchMails.fulfilled, (state, action) => {
				// state.params = action.payload.params
				// state.searchResults = action.payload.data.emails

				// Ensure the emails are stored in the right folder
				const folderPath = 'Search'
				const emails = action.payload.data.emails
				const currentPage = action.payload.data.pagination?.page || 1 // Default to page 1 if not provided

				// Store search results in a virtual folder
				if (!state.mails) {
					state.mails = {}
				}

				state.mails = {
					...state.mails,
					[folderPath]: {
						...state.mails[folderPath], // Preserve existing folder data
						pages: {
							...(state.mails[folderPath]?.pages || {}), // Preserve existing pages
							[currentPage]: emails // Add/Update current page with new emails
						}
					}
				}
			})
			.addCase(flagMails.fulfilled, (state, action) => {
				const ids = Object.keys(action.payload)

				// Iterate through each folder in mails
				Object.keys(state.mails).forEach((folder) => {
					// Ensure the folder has pages
					if (state.mails[folder]?.pages) {
						// Iterate through each page in the folder
						Object.keys(state.mails[folder].pages).forEach((pageKey) => {
							// Ensure the page has emails (array)
							if (Array.isArray(state.mails[folder].pages[pageKey])) {
								state.mails[folder].pages[pageKey] = state.mails[folder].pages[pageKey].map((email) => {
									if (ids.includes(email.messageId)) {
										return {
											...email,
											flags: action.payload[email.messageId]
										}
									}
									return email
								})
							}
						})
					}
				})

				// Update currentMail if it matches any of the flagged IDs
				if (state.currentMail && ids.includes(state.currentMail.messageId)) {
					state.currentMail = {
						...state.currentMail,
						flags: action.payload[state.currentMail.messageId]
					}
				}
			})
			.addCase(markUnread.fulfilled, (state, action) => {
				const ids = Object.keys(action.payload)

				// Iterate through each folder in mails
				Object.keys(state.mails).forEach((folder) => {
					// Ensure the folder has pages
					if (state.mails[folder]?.pages) {
						// Iterate through each page in the folder
						Object.keys(state.mails[folder].pages).forEach((pageKey) => {
							// Ensure the page has emails (array)
							if (Array.isArray(state.mails[folder].pages[pageKey])) {
								state.mails[folder].pages[pageKey] = state.mails[folder].pages[pageKey].map((email) => {
									if (ids.includes(email.messageId)) {
										return {
											...email,
											flags: action.payload[email.messageId]
										}
									}
									return email
								})
							}
						})
					}
				})

				// Update currentMail if it matches any of the flagged IDs
				if (state.currentMail && ids.includes(state.currentMail.messageId)) {
					state.currentMail = {
						...state.currentMail,
						flags: action.payload[state.currentMail.messageId]
					}
				}
			})
			.addCase(moveMails.fulfilled, (state, action) => {
				const moved = []

				// Iterate through all folders to remove moved emails
				Object.keys(state.mails).forEach((folder) => {
					if (state.mails[folder]?.pages) {
						// Iterate through each page in the folder
						Object.keys(state.mails[folder].pages).forEach((pageKey) => {
							// Filter out moved emails and collect them
							state.mails[folder].pages[pageKey] = state.mails[folder].pages[pageKey].filter((email) => {
								if (action.payload.moved.includes(email.messageId)) {
									moved.push({ ...email }) // Add to moved list
									return false // Remove from current folder
								}
								return true // Keep in the page
							})
						})
					}
				})

				/**
				 * Before moving, check if the target folder was already loaded.
				 * If not, do nothing. Emails will be loaded when the folder is accessed.
				 */
				if (state.mails[action.payload.to.path]) {
					const targetPages = Object.keys(state.mails[action.payload.to.path].pages).sort()
					const firstPageKey = targetPages.length > 0 ? targetPages[0] : null

					if (firstPageKey) {
						// Add moved emails to the first page of the target folder
						state.mails[action.payload.to.path].pages[firstPageKey] = [
							...moved,
							...state.mails[action.payload.to.path].pages[firstPageKey]
						]
					} else {
						// If no pages exist, create the first page
						state.mails[action.payload.to.path].pages['page_1'] = [...moved]
					}
				}

				// Update folder metadata for both source and target folders
				const from = Object.keys(action.payload.from)
				state.folders.forEach((folder) => {
					if (from.includes(folder.path)) {
						Object.assign(folder, { exists: action.payload.from[folder.path].exists })
					}
					if (action.payload.to.path === folder.path) {
						Object.assign(folder, { exists: action.payload.to.exists })
					}
				})
			})
			.addCase(sendMail.fulfilled, (state, action) => {
				if (action.payload.sent) {
					// Only add to 'Sent' if it already exists in state.mails
					// Otherwise emails will be loaded when the folder will be accessed
					if (state.mails['Sent']) {
						// state.mails['Sent'].unshift({ ...action.payload.email })

						// Retrieve the first available page dynamically
						const sentPages = Object.keys(state.mails['Sent'].pages).sort() // Sort to ensure order
						const firstPageKey = sentPages.length > 0 ? sentPages[0] : null

						if (firstPageKey) {
							// Add the new email to the first available page
							state.mails['Sent'].pages[firstPageKey].unshift({ ...action.payload.email })
						} else {
							// If no pages exist, create the first page and add the email
							state.mails['Sent'].pages['1'] = [{ ...action.payload.email }]
						}
					}

					// Remove the draft if Drafts folder is loaded and draft exists
					if (state.mails['Drafts']) {
						// state.mails['Drafts'] = state.mails['Drafts'].filter((draft) => draft.messageId !== action.payload.email.messageId)
						Object.keys(state.mails['Drafts'].pages).forEach((pageKey) => {
							state.mails['Drafts'].pages[pageKey] = state.mails['Drafts'].pages[pageKey].filter(
								(draft) => draft.messageId !== action.payload.email.messageId
							)
						})
					}

					// Update the 'Sent' folder's total email count if it exists
					const sentFolder = state.folders.find((folder) => folder.path === 'Sent')
					if (sentFolder) {
						sentFolder.exists = action.payload.totalSent
					}

					// Update the 'Drafts' folder's total email count if it exists
					const draftsFolder = state.folders.find((folder) => folder.path === 'Drafts')
					if (draftsFolder) {
						draftsFolder.exists = action.payload.totalDrafts
					}
				}
			})
			.addCase(saveMail.fulfilled, (state, action) => {
				if (action.payload.saved) {
					// Only add to 'Drafts' if it already exists in state.mails
					// Otherwise emails will be loaded when the folder will be accessed
					if (state.mails['Drafts']) {
						// state.mails['Drafts'].unshift({ ...action.payload.email })

						// Retrieve the first available page dynamically
						const draftPages = Object.keys(state.mails['Drafts'].pages).sort() // Sort to ensure order
						const firstPageKey = draftPages.length > 0 ? draftPages[0] : null

						if (firstPageKey) {
							// Add the new draft to the first available page
							state.mails['Drafts'].pages[firstPageKey].unshift({ ...action.payload.email })
						} else {
							// If no pages exist, create the first page and add the draft email
							state.mails['Drafts'].pages['page_1'] = [{ ...action.payload.email }]
						}
					}

					// Update the 'Drafts' folder's total email count if it exists
					const draftsFolder = state.folders.find((folder) => folder.path === 'Drafts')
					if (draftsFolder) {
						draftsFolder.exists = action.payload.totalDrafts
					}
				}
			})
			.addCase(updateMails.fulfilled, (state, action) => {
				// ????? Check this
				function updateMailData(email) {
					Object.assign(email, action.payload.dataToUpdate)
				}
				state.mails.forEach((email) => {
					if (action.payload.emailIds.includes(email.uid)) {
						updateMailData(email)
					}
				})
			})
			/*.addCase(paginateMail.fulfilled, (state, action) => {
				// ????? Should be removed
				const data = action.payload
				const dataIndex = state.mails.findIndex((i) => i.uid === data.uid)
				dataIndex === 0 ? (data.hasPreviousMail = false) : (data.hasPreviousMail = true)
				dataIndex === state.mails.length - 1 ? (data.hasNextMail = false) : (data.hasNextMail = true)
				state.currentMail = data
			})*/
			.addCase(selectCurrentMail.fulfilled, (state, action) => {
				const mail = action.payload.data
				const activeFolder = action.payload.activeFolder

				// Ensure the folder and pages exist in state
				if (!state.mails[activeFolder.path] || !state.mails[activeFolder.path].pages) {
					console.warn(`Folder ${activeFolder.path} or its pages do not exist in state.`)
					return
				}

				// Iterate through each page to find and update the mail
				Object.keys(state.mails[activeFolder.path].pages).forEach((pageKey) => {
					const mails = state.mails[activeFolder.path].pages[pageKey]
					const index = mails.findIndex((i) => i.messageId === mail.messageId)

					if (index !== -1) {
						// Found the mail, update it
						const updatedMail = {
							...mails[index],
							flags: {
								...mails[index].flags,
								seen: 'Seen' // Mark the mail as seen
							}
						}

						// Replace the mail in the current page
						state.mails[activeFolder.path].pages[pageKey][index] = updatedMail
					}
				})

				state.currentMail = mail

				// Update unseen count in both the target folder and the 'Unread' folder if they have unseen messages
				state.folders = state.folders.map((folder) => {
					if (folder.path === mail.folder && folder.unseen > 0) {
						// Decrease unseen count for the target folder
						return { ...folder, unseen: folder.unseen - 1 }
					}
					if (folder.path === 'Unread' && folder.exists > 0) {
						// Decrease unseen count for the 'Unread' folder
						return { ...folder, exists: folder.exists - 1 }
					}
					return folder
				})

				// Remove the message from 'Unread' folder in state.mails if it exists
				/*if (state.mails['Unread']) {
					state.mails['Unread'] = state.mails['Unread'].filter((unreadMail) => unreadMail.messageId !== mail.messageId)
				}*/
				if (state.mails['Unread']?.pages) {
					Object.keys(state.mails['Unread'].pages).forEach((pageKey) => {
						state.mails['Unread'].pages[pageKey] = state.mails['Unread'].pages[pageKey].filter(
							(unreadMail) => unreadMail.messageId !== mail.messageId
						)
					})
				}
			})
	}
})

export const { selectMail, selectAllMail, resetSelectedMail, resetSearchResults } = appEmailSlice.actions

export default appEmailSlice.reducer
