const Footer = () => {
	return (
		<p className='clearfix mb-0'>
			<span className='float-md-end d-block d-md-inline-block mt-25'>
				© TRABO-PLUS, {new Date().getFullYear()}
				<span className='d-none d-sm-inline-block'></span>
			</span>
			{/*<span className="float-md-end d-none d-md-block">
				Made by{' '}
				<a href="https://garm.pw" target="_blank" rel="noopener noreferrer">
					garm.pw
				</a>
			</span>*/}
		</p>
	)
}

export default Footer
