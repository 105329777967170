// ** React Imports
import { Fragment } from 'react'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'

import { useTranslation } from 'react-i18next'

// ** Third Party Components
import classnames from 'classnames'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Check, Mail } from 'react-feather'

import { resetNotifications } from '@src/redux/notifications'

// ** Reactstrap Imports
import { Badge, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'

const MailboxNotifications = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	// ** Get notifications from Redux store
	const notifications = useSelector((state) => state.notifications)

	// ** Function to render Notifications
	const renderNotificationItems = () => {
		return (
			<PerfectScrollbar
				component='li'
				className='media-list scrollable-container'
				options={{
					wheelPropagation: false
				}}
			>
				{notifications.map((item, index) => {
					return (
						<div
							key={index}
							className={classnames('list-item d-flex', {
								'align-items-start': true
							})}
						>
							<Fragment>
								<div className='list-item-body'>
									<b>{t('From')}:</b> {item.from}
									<br />
									<b>{t('Subject')}:</b> {item.subject}
								</div>
							</Fragment>
						</div>
					)
				})}
			</PerfectScrollbar>
		)
	}

	return (
		<UncontrolledDropdown
			tag='li'
			className='dropdown-notification nav-item me-25'
			onToggle={(e, isOpen) => {
				e.preventDefault()
				if (!isOpen) {
					// Reset notifications when dropdown closes
					dispatch(resetNotifications())
				}
			}}
		>
			<DropdownToggle
				tag='a'
				className='nav-link'
				href='/'
				onClick={(e) => {
					e.preventDefault()
				}}
			>
				<Mail size={21} />
				{notifications.length > 0 && (
					<Badge pill color='success' className='badge-up'>
						{notifications.length}
					</Badge>
				)}
			</DropdownToggle>
			<DropdownMenu end tag='ul' className='dropdown-menu-media mt-0'>
				{renderNotificationItems()}
			</DropdownMenu>
		</UncontrolledDropdown>
	)
}

export default MailboxNotifications
