// ** React Imports
import { lazy } from 'react'

const Authorization = lazy(() => import('../../views/logs/authorization'))
const SuspiciousActivity = lazy(() => import('../../views/logs/suspicious-activity'))

const LogsRoutes = [
	{
		path: '/logs/authorization',
		element: <Authorization />,
		meta: {
			action: 'manage',
			resource: 'logs'
		}
	},
	{
		path: '/logs/suspicious-activity',
		element: <SuspiciousActivity />,
		meta: {
			action: 'manage',
			resource: 'logs'
		}
	}
]

export default LogsRoutes
