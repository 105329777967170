// ** Icons Import
import { User, Users, Mail, Shield, ShieldOff, Smartphone } from 'react-feather'

export default [
	{
		header: 'AUTHORIZATION',
		action: 'manage',
		resource: 'auth'
	},
	{
		id: 'authUsers',
		title: 'Users',
		icon: <User size={20} />,
		action: 'manage',
		resource: 'users',
		navLink: '/auth/users'
	},
	{
		id: 'authEmailAccounts',
		title: 'Email Accounts',
		icon: <Mail size={20} />,
		action: 'manage',
		resource: 'email-accounts',
		navLink: '/auth/email-accounts'
	},
	{
		id: 'authBotAccounts',
		title: 'Bot Users',
		icon: <Smartphone size={20} />,
		action: 'manage',
		resource: 'bot-accounts',
		navLink: '/auth/bot-accounts'
	},
	{
		id: 'authUserGroups',
		title: 'User Groups',
		icon: <Users size={20} />,
		action: 'manage',
		resource: 'user-groups',
		navLink: '/auth/user-groups'
	},
	{
		id: 'ip-whitelists',
		title: 'IP Whitelists',
		icon: <ShieldOff size={20} />,
		action: 'manage',
		resource: 'ip-whitelists',
		navLink: '/auth/ip-whitelists'
	},
	{
		id: 'ip-blacklists',
		title: 'IP Blacklists',
		icon: <Shield size={20} />,
		action: 'manage',
		resource: 'ip-blacklists',
		navLink: '/auth/ip-blacklists'
	}
]
