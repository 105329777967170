import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
	jwtConfig = { ...jwtDefaultConfig }
	isRefreshing = false
	pendingRequests = []

	constructor(jwtOverrideConfig, dispatch) {
		this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }
		this.dispatch = dispatch

		// Create a separate Axios instance for refresh token calls
		this.axiosRefresh = axios.create({
			baseURL: process.env.REACT_APP_API_URL,
			withCredentials: true
		})

		this.setupInterceptors()
	}

	// Setup Interceptors
	setupInterceptors() {
		const isDevMode = process.env.REACT_APP_NODE_ENV === 'development'

		// ** Request Interceptor
		axios.interceptors.request.use(
			(config) => {
				config.withCredentials = true

				// Add Authorization header for non-refresh requests
				if (isDevMode && config.url !== this.jwtConfig.refreshEndpoint) {
					const accessToken = localStorage.getItem('accessToken')
					if (accessToken) {
						config.headers['Authorization'] = `Bearer ${accessToken}`
					}
				}
				return config
			},
			(error) => Promise.reject(error)
		)

		// ** Response Interceptor
		axios.interceptors.response.use(
			(response) => response,
			async (error) => {
				const { config, response } = error

				// Skip retry for login requests
				if (config?.skipAuthRetry) {
					console.warn('Skipping token refresh for login request.')
					return Promise.reject(error)
				}

				// Handle 401 Unauthorized
				if (response && response.status === 401 && !config._retry) {
					console.log('Performing token refresh...')
					config._retry = true

					if (!this.isRefreshing) {
						this.isRefreshing = true

						try {
							const newAccessToken = await this.refreshToken()
							console.log('Token refreshed successfully.')

							// Resolve all pending requests with the new token
							this.pendingRequests.forEach((callback) => callback(newAccessToken))
							this.pendingRequests = []

							// Retry the original failed request
							config.headers['Authorization'] = `Bearer ${newAccessToken}`
							return axios(config)
						} catch (refreshError) {
							console.error('Token refresh failed:', refreshError)
							// Fail all queued requests
							this.pendingRequests.forEach((callback) => callback(null))
							this.pendingRequests = []
							this.logout()
							return Promise.reject(refreshError)
						} finally {
							this.isRefreshing = false
							// this.pendingRequests = []
						}
					}

					// Queue requests while token is refreshing
					return new Promise((resolve) => {
						console.log('Queuing request during token refresh...')
						this.pendingRequests.push((token) => {
							if (token) {
								config.headers['Authorization'] = `Bearer ${token}`
								resolve(axios(config))
							} else {
								// config._retry = false
								resolve(Promise.reject(error))
							}
						})
					})
				}

				// Pass other errors through
				return Promise.reject(error)
			}
		)
	}

	// Login Method
	login(...args) {
		return axios.post(this.jwtConfig.loginEndpoint, ...args)
	}

	// Verify Pin Method
	verifyPin(...args) {
		return axios.post(this.jwtConfig.verifyPinEndpoint, ...args)
	}

	// Logout Method
	logout = async () => {
		console.log('Performing logout due to token refresh failure...')
		try {
			await axios.post(this.jwtConfig.logoutEndpoint, {}, { withCredentials: true })

			// Clear tokens and userData
			localStorage.removeItem('userData')
			localStorage.removeItem('account')
			localStorage.removeItem('accessToken')
			localStorage.removeItem('refreshToken')

			// Redirect to login
			window.location.href = '/login'
		} catch (error) {
			console.error('Logout request failed:', error)

			// Ensure cleanup happens even if logout API fails
			localStorage.removeItem('userData')
			localStorage.removeItem('account')
			localStorage.removeItem('accessToken')
			localStorage.removeItem('refreshToken')

			window.location.href = '/login'
		}
	}

	// Refresh Token Method (Using Separate Axios Instance)
	refreshToken = async () => {
		console.log('Refreshing in progress...')
		const isDevMode = process.env.REACT_APP_NODE_ENV === 'development'

		// Retrieve refresh token in development mode
		const refreshToken = isDevMode ? localStorage.getItem('refreshToken') : null

		if (isDevMode && !refreshToken) {
			throw new Error('No refresh token available in development mode')
		}

		const headers = isDevMode ? { Authorization: `Refresh ${refreshToken}` } : {}

		try {
			const response = await this.axiosRefresh.post(
				this.jwtConfig.refreshEndpoint,
				{},
				{
					headers
				}
			)

			console.log('Refresh token response:', response)

			const { accessToken, refreshToken: newRefreshToken } = response.data

			// Store tokens in localStorage in development mode
			if (isDevMode) {
				localStorage.setItem('accessToken', accessToken)
				localStorage.setItem('refreshToken', newRefreshToken)
			}

			return accessToken
		} catch (error) {
			console.error('Error during refresh token request:', error)

			if (error.response?.status === 401 || error.response?.status === 403) {
				console.warn('Token refresh failed. Logging out...')
				this.logout()
			}

			return Promise.reject(error)
		} finally {
			// this.isRefreshing = false
			// this.pendingRequests = []
			console.log('Refresh token attempt completed.')
		}
	}
}
